import { Component, Input, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Event } from 'src/app/models/event';
import { EventApplication } from 'src/app/models/relations/events/event-application';

@Component({
  selector: 'apply-event',
  templateUrl: './apply-event.component.html',
  styleUrls: ['./apply-event.component.scss'],
})
export class ApplyEventComponent implements OnInit{
  @Input() public event: Event;
  @Input() public withTheme = true
  @Input() ref:MatBottomSheetRef<any>

  constructor(
    private router:Router
  ) {}

  public priceText(application: EventApplication) {
    if (application.price == 0) {
      return 'Ücretsiz';
    }
    return (
      application.price +
      ' ' +
      this.convertCurrencyToSymbol(application.currency)
    );
  }

  public generateRoute(application: EventApplication) {
    return `/events/${this.event.id}/purchase/${application.id}`;
  }

  public openPurchasePage(application: EventApplication) {
    this.router.navigate([this.generateRoute(application)]);
    if (this.ref) {
      this.ref.dismiss()
    }
  }

  public hasLimitWarning(application: EventApplication) {
    const remaining = application.maxTickets - application.ticketCount;
    return application.maxTickets > 0 && remaining <= 10;
  }

  public soldOut(application: EventApplication) {
    return (
      application.maxTickets > 0 &&
      application.ticketCount >= application.maxTickets
    );
  }

  public convertCurrencyToSymbol(currency: string) {
    if (currency == 'TRY') {
      return '₺';
    } else if (currency == 'USD') {
      return '$';
    }
    return currency;
  }

  ngOnInit(): void {
    
  }
}
