import {
  Inject,
  Injectable,
  Optional,
  PLATFORM_ID,
  TransferState,
  makeStateKey,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpErrorHandlerService } from '@common/core/http/http-error-handler.service';
import { AppHttpContext } from './http-context';
import { FormGroup } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { TOKEN } from './express.token';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { environment } from '../../../environments/environment';

const dataKey = makeStateKey<{ data: string }>('api_key');

@Injectable({
  providedIn: 'root',
})
export class AppHttpClient {
  static prefix = `${environment.api}/api`;
  public defaultContext = new AppHttpContext();
  public token$ = new BehaviorSubject<string | undefined>(undefined);

  constructor(
    protected httpClient: HttpClient,
    protected errorHandler: HttpErrorHandlerService,
    private transferState: TransferState,
    private cookieService: SsrCookieService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(TOKEN) private serverToken: string
  ) {
    this.token$.next(this.getToken());
    this.defaultContext.init(
      httpClient,
      errorHandler,
      transferState,
      this.token$,
      platformId
    );
  }

  static prefixUri(uri: string) {
    if (
      uri.indexOf('://') > -1 ||
      uri.startsWith(AppHttpClient.prefix) ||
      uri.startsWith('api')
    ) {
      return uri;
    }
    return `${AppHttpClient.prefix}/${uri}`;
  }

  public setToken(token: string) {
    if (isPlatformBrowser(this.platformId)) {
      if (!token) {
        this.cookieService.delete('token','/');
      } else {
        const today = new Date();
        const newDate =  new Date(today.getTime() + 1000 * 60 * 60 * 24 * 365);
        this.cookieService.set('token', token, {
          path: '/',
          expires: newDate,
        });
      }
      this.httpClient.post('/set-token', { token }).subscribe({
        next: (response) => {
          console.log(response);
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
    this.token$.next(token);
  }

  public getToken() {
    if (isPlatformBrowser(this.platformId)) {
      console.log('get token from cookie', this.cookieService.get('token'));
      return this.cookieService.get('token');
    } else {
      return this.serverToken;
    }
  }

  public withContext(context: AppHttpContext, formGroup?: FormGroup) {
    this.token$.next(this.getToken());
    context.init(
      this.httpClient,
      this.errorHandler,
      this.transferState,
      this.token$,
      this.platformId
    );
    if (formGroup) {
      context.formGroup = formGroup;
    }
    return context;
  }

  public withForm(formGroup: FormGroup) {
    return this.withContext(new AppHttpContext(), formGroup);
  }

  public get<T>(uri: string, params = {}, options: object = {}): Observable<T> {
    console.log('get', uri);
    return this.defaultContext.get<T>(uri, params, options);
  }

  public post<T>(uri: string, payload: object = null): Observable<T> {
    return this.defaultContext.post<T>(uri, payload);
  }

  public put<T>(uri: string, payload: object = {}): Observable<T> {
    return this.defaultContext.put<T>(uri, payload);
  }

  public delete<T>(uri: string, payload: object = {}): Observable<T> {
    return this.defaultContext.delete<T>(uri, payload);
  }

  public postWithProgress(uri: string, params: FormData) {
    return this.defaultContext.postWithProgress(uri, params);
  }

  protected transformQueryParams(params: object | null) {
    return this.defaultContext.transformQueryParams(params);
  }

  protected spoofHttpMethod(
    params: object | FormData,
    method: 'PUT' | 'DELETE'
  ): object | FormData {
    return this.defaultContext.spoofHttpMethod(params, method);
  }
}
