import { Component, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@common/auth/auth.service';
import { UploadService } from '@common/core/upload/upload.service';
import { EventsService } from '@common/services/events.service';

@Component({
  selector: 'add-event-dialog',
  templateUrl: './add-event-dialog.component.html',
  styleUrls: ['./add-event-dialog.component.scss'],
})
export class AddEventDialogComponent {
  constructor(
    public ref: MatDialogRef<AddEventDialogComponent>,
    public fb: FormBuilder,
    public uploads: UploadService,
    public events:EventsService,
    public router:Router,
    public auth:AuthService
  ) {}

  @ViewChild('stepper') public stepper;
  @ViewChild('editor') public editor;

  public generalGroup = this.fb.group({
    name: ['', Validators.required],
    headnote: ['', Validators.required],
    location: ['', Validators.required],
  });

  public tagGroup = this.fb.group({
    tags: this.fb.array([]),
  });

  public get tags() {
    return this.tagGroup.get('tags') as FormArray;
  }

  public imageGroup = this.fb.group({
    coverImage: [null, Validators.required],
    imageUrl: [''],
  });

  public contentGroup = this.fb.group({
    description: ['', Validators.required],
  });

  public timeGroup = this.fb.group({
    startDate: ['', Validators.required],
    startTime: [''],
    endDate: ['', Validators.required],
    endTime: [''],
  });

  public contentChange() {
    this.contentGroup.get('description').setValue(this.editor.getContents());
  }

  public pickImage() {
    this.uploads.openUploadDialog$('image', false, {
      aspectRatio: 16 / 9,
    }).subscribe((files) => {
      this.imageGroup.patchValue({ coverImage: files[0] });
      this.imageGroup.get('imageUrl').setValue(files[0].imagesData.variants[0]);
    });
  }

  public canSubmit() {
    return (
      this.generalGroup.valid &&
      this.imageGroup.valid &&
      this.contentGroup.valid &&
      this.timeGroup.valid
    );
  }

  public submit() {
    this.stepper.next();
    const data:any = {
      ...this.generalGroup.value,
      ...this.tagGroup.value,
      ...this.imageGroup.value,
      ...this.contentGroup.value,
      ...this.timeGroup.value,
    };
    data.organizatorID = this.auth.selectedMember.profileId;
    data.organizatorType = this.auth.selectedMember.profileType;

    if (data.startDate && data.startTime && data.startTime.includes(":")) {
      const startDate = new Date(data.startDate);
      const [hours, minutes] = data.startTime.split(":");
      startDate.setHours(parseInt(hours, 10));
      startDate.setMinutes(parseInt(minutes, 10));
      data.startDate = startDate;
    }

    if (data.endDate && data.endTime && data.endTime.includes(":")) {
      const endDate = new Date(data.endDate);
      const [hours, minutes] = data.endTime.split(":");
      endDate.setHours(parseInt(hours, 10));
      endDate.setMinutes(parseInt(minutes, 10));
      data.endDate = endDate;
    }

    this.events.create(data as any).subscribe((data) => {
      this.ref.close();
      
      this.router.navigate(['/admin/event/',data.data.id]);
    });
  }
}
