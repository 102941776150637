import { Component, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@common/auth/auth.service';
import { UploadService } from '@common/core/upload/upload.service';
import { EventsService } from '@common/services/events.service';
import { ProjectsService } from '@common/services/projects.service';

@Component({
  selector: 'add-project-dialog',
  templateUrl: './add-project-dialog.component.html',
  styleUrls: ['./add-project-dialog.component.scss'],
})
export class AddProjectDialogComponent {
  constructor(
    public ref: MatDialogRef<AddProjectDialogComponent>,
    public fb: FormBuilder,
    public uploads: UploadService,
    public projects: ProjectsService,
    public router: Router,
    public auth: AuthService
  ) {}

  @ViewChild('stepper') public stepper;
  @ViewChild('editor') public editor;

  public generalGroup = this.fb.group({
    name: ['', Validators.required],
    headnote: ['', Validators.required],
  });

  public tagGroup = this.fb.group({
    tags: this.fb.array([]),
  });

  public get tags() {
    return this.tagGroup.get('tags') as FormArray;
  }

  public imageGroup = this.fb.group({
    coverImage: [null, Validators.required],
    imageUrl: [''],
  });

  public contentGroup = this.fb.group({
    description: ['', Validators.required],
  });

  public timeGroup = this.fb.group({
    startDate: ['', Validators.required],
  });

  public contentChange() {
    this.contentGroup.get('description').setValue(this.editor.getContents());
  }

  public pickImage() {
    this.uploads
      .openUploadDialog$('image', false, {
        aspectRatio: 16 / 3,
      })
      .subscribe((files) => {
        this.imageGroup.patchValue({ coverImage: files[0] });
        this.imageGroup
          .get('imageUrl')
          .setValue(files[0].imagesData.variants[0]);
      });
  }

  public canSubmit() {
    return (
      this.generalGroup.valid &&
      this.imageGroup.valid &&
      this.contentGroup.valid &&
      this.timeGroup.valid
    );
  }

  public submit() {
    this.stepper.next();
    const data: any = {
      ...this.generalGroup.value,
      ...this.tagGroup.value,
      ...this.imageGroup.value,
      ...this.contentGroup.value,
      ...this.timeGroup.value,
    };
    data.ownerId = this.auth.selectedMember.profileId;
    data.ownerType = this.auth.selectedMember.profileType;
    this.projects.create(data as any).subscribe((data) => {
      this.ref.close();

      this.router.navigate(['/admin/project/', data.data.id]);
    });
  }
}
