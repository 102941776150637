import { imageFile } from '@common/core/pipes/image-file.pipe';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomPage } from 'src/app/models/custom-page';
import { Event } from 'src/app/models/event';
import { Post } from 'src/app/models/post';
import { DomSanitizer } from '@angular/platform-browser';
import { File } from 'src/app/models/file';

@Component({
  selector: 'featured-content',
  templateUrl: './featured-content.component.html',
  styleUrls: ['./featured-content.component.scss'],
})
export class FeaturedContentComponent {
  constructor(private sanitizer: DomSanitizer) {}

  @Input() public item: Post | Event | CustomPage | string;
  @Input() public showButton: boolean = false;
  @Output() public buttonClick = new EventEmitter<void>();

  public getMappedData$ = new Observable<{
    type: string;
    userName?: string;
    userImage?: any;
    text: string;
    image?: any;
    title?: string;
    video?: File;
  }>((observer) => {
    if (typeof this.item === 'string') {
      //TODO: fetch data from server
    } else if (
      this.item.constructor.name === 'Post' ||
      (this.item as any).text
    ) {
      console.log(this.item);
      const post = this.item as Post;
      const author = post.postedBy as any;
      observer.next({
        type: 'post',
        userName: author.display_name || author.name,
        userImage: author.avatar,
        text: post.text,
        image: post.images && post.images.length > 0 ? post.images[0] : null,
        video: post.videos?.length > 0 ? post.videos[0] : null,
      });
    } else if (this.item.constructor.name === 'Event') {
      const event = this.item as Event;
      const creator = event.creator as any;
      observer.next({
        type: 'event',
        userName: creator.display_name,
        userImage: creator.avatar,
        text: event.description,
        image: event.image,
        title: event.name,
      });
    } else if (this.item.constructor.name === 'CustomPage') {
      const page = this.item as CustomPage;
      const user = page.user as any;
      observer.next({
        type: 'page',
        userName: user.display_name,
        userImage: user.avatar,
        text: page.body.slice(0, 20),
        image: page.image,
        title: page.title,
      });
    }
  });

  public getOpenText(type: string) {
    if (type === 'post') {
      return 'Devamını Oku';
    } else if (type === 'event') {
      return 'Etkinliğe Git';
    } else if (type === 'page') {
      return 'Gönderiye Git';
    }
    return 'Git';
  }

  public getTypeText(type: string) {
    if (type === 'post') {
      return 'Gönderi';
    } else if (type === 'event') {
      return 'Etkinlik';
    } else if (type === 'page') {
      return 'Blog Gönderisi';
    }
    return 'Diğer';
  }

  public getButtonText(type: string) {
    if (type === 'post') {
      return 'Gönderiyi Öne Çıkar';
    }
    return 'Öne Çıkar';
  }

  public getLink(data) {
    if (data.type === 'post') {
      return `/post/${(this.item as Post).id}`;
    } else if (data.type === 'event') {
      return `/event/${(this.item as Event).id}`;
    } else if (data.type === 'page') {
      return `/blog/${(this.item as CustomPage).id}`;
    }
    return '';
  }
}
